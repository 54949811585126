import size from "lodash/size";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import get from "lodash/get";
import filter from "lodash/filter";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  DEFAULT_TIME_END_24HR,
  DEFAULT_TIME_START_24HR,
} from "../v2_actions/time";
import {
  NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING,
  NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON,
  galleryList,
} from "../v2_actions/gallery";
import { Tombstone, TombstoneContainer } from "./Tombstone";
import {
  basePhotographList,
  photographList,
} from "../v2_actions/customer_photograph";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import CachedIcon from "@mui/icons-material/Cached";
import CarouselPhotos from "../views/galleryphotographs/carousel";
import GalleryImage from "../views/galleryphotographs/GalleryImage";
import { GalleryPasswordModal } from "./map/googlemap/GalleryPasswordModal";
import PhotoAlbum from "react-photo-album";
import { ScrollToTopButton } from "./ScrollToTopButton";
import { Typewriter } from "./TypewriterLoader";
import { galleryEventDirectAccessUrl } from "../v2_actions/customer_gallery_event_direct_access_url";
import { makeLogger } from "../utils/o11y/logger";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { setZoom } from "../actions/gallery";
import { trolleyList } from "../v2_actions/trolley";
import { useIsMobile } from "../actions/ui";

const DEFAULT_NUM_OF_PHOTOS_TO_RENDER = 100;

const logger = makeLogger({
  enabled: true,
  label: "GalleryPhotographs",
  color: "maroon",
});

const useStyles = makeStyles((theme) => ({
  numPhotosText: {
    left: 15,
    color: "#000",
    position: "relative",
    textTransform: "Capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      left: 0,
      top: 60,
      zIndex: 9999,
      fontSize: 16,
      marginBottom: 70,
    },
  },
  noPhotosFoundText: {
    top: 100,
    width: "100%",
    display: "block",
    textAlign: "center",
    position: "relative",
    alignContent: "center",
  },
  mapPhotograph: {
    width: "100%",
    position: "relative",
    margin: "0px 0px 20px",
  },
  mapPhotographWrapper: {
    zIndex: 1,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "0px",
      visibility: "hidden",
    },
  },
  mapPhotographTextWrapper: {
    top: 150,
    zIndex: 9,
    height: 100,
    width: "50%",
    margin: "auto",
    display: "grid",
    textAlign: "center",
    position: "relative",
    marginBottom: "-60px",
    alignContent: "center",
    background: "#7a7a7a9c",
  },
  scrollToPosition: {
    scrollMarginTop: "50px",
  },
  photoAlbumWrapper: {
    zIndex: 99,
    position: "relative",
  },
  loadingTypeWriterWrapper: {
    marginTop: "150px",
    width: "100%",
    textAlign: "center",
  },
  mapPhotographText: {
    margin: 0,
    color: "#fff",
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    position: "relative",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
  mapPhotographViewMoreButtonWrapper: {
    top: "70vh",
    zIndex: 9,
    height: 36,
    width: 210,
    cursor: "pointer",
    margin: "auto",
    display: "grid",
    textAlign: "center",
    position: "relative",
    marginBottom: "-60px",
    alignContent: "center",
    background: "#7a7a7a9c",
  },
  mapPhotographViewMoreButton: {
    margin: 0,
    color: "#fff",
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    position: "relative",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
  readMoreBtnWrapper: {
    bottom: 52,
    height: 50,
    position: "relative",
    background: "#eeeeeea3",
    marginBottom: 10,
    "& button": {
      padding: "2px 30px",
    },
  },
  seeMoreButton: {
    margin: 0,
    fontSize: 15,
    fontWeight: 600,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const GalleryPhotographs = ({
  gallery_id,
  bibNumber,
  timeOfActivity,
  onClearFilters,
  canSelectForCheckout,
  heroPhotoEnabled,
  isPrivateEvent,
}) => {
  const classes = useStyles();
  const containerRef = useRef();
  const pageTopSentinel = useRef();
  const loadMoreSentinel = useRef();
  const dispatch = useDispatch();
  const photoGalleryRef = useRef();
  const galleryId = gallery_id;
  const isMobile = useIsMobile();
  const [photoLayoutList, setPhotoLayoutList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [carouselVisible, setCarouselVisible] = useState(false);
  const [isAppendingContent, setIsAppendingContent] = useState(false); // determines whether additional photos are being appended
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedImgId, setSelectedImgId] = useState(null);
  const [numberOfPhotosToRender] = useState(DEFAULT_NUM_OF_PHOTOS_TO_RENDER);
  const hasDirectUrlAccess = get(
    head(useSelector(() => galleryEventDirectAccessUrl.getVisibleObjects())),
    "gallery_event_id",
  );
  const trolley = useSelector(() => trolleyList.getTrolley());
  const zoom = useSelector((state) => state.galleryReducer.zoom);
  const gallery = useSelector(() => head(galleryList.getVisibleObjects()));
  const galleryListIsLoading = galleryList.isLoading();
  const [shouldShowMapPhotographText, setShouldShowMapPhotographText] =
    useState(false);
  const [shouldShowGalleryPhotographs, setShouldShowGalleryPhotographs] =
    useState(isMobile);
  const [galleryPasswordCorrect, setGalleryPasswordCorrect] = useState(false);
  const [hasPasswordBypassprivilege, setHasPasswordBypassprivilege] =
    useState(null);
  const photographs = photographList.getVisibleObjects();
  const numPhotos = size(photographs);
  const photographsIsEmpty = isEmpty(photographs);
  const photographs_are_loading = useSelector(() => photographList.isLoading());
  const mapPhotographId = get(gallery, "map_photograph");
  const mapPhotograph = head(
    filter(photographs, (photograph) => photograph.id === mapPhotographId),
  );
  const mapPhotographGalleryDownloadUrl = get(mapPhotograph, [
    "gallery_size_file_info",
    "download_url",
  ]);
  const customerFaceToken = useSelector(() =>
    get(basePhotographList.getFilter(), "customer_face_token"),
  );
  const pagination = useSelector(() => photographList.getPagination());
  const photographListFilter = photographList.getFilter();
  const [isApproachingTopOfGalleryPage, setIsApproachingTopOfGalleryPage] =
    useState(false);
  const default_private_event_token = "99b294e5-4a69-477f-8930-b6ee279a1cd0";
  const allPagesLoaded = pagination.num_pages === pagination.current_page;
  const galleryPhotographFiltersArePristine =
    !customerFaceToken &&
    !bibNumber &&
    timeOfActivity?.start === DEFAULT_TIME_START_24HR &&
    timeOfActivity?.end === DEFAULT_TIME_END_24HR;

  const numPhotosWhenShowingShowMoreButton =
    window.LOCAL_SETTINGS.NUM_PHOTOS_IN_BUFFER ||
    NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON;

  const shouldShowNoPhotosFound =
    !photographs_are_loading &&
    numPhotos === 0 &&
    !galleryPhotographFiltersArePristine;

  const conditionsToRenderPhotosAreMet =
    isAppendingContent ||
    Boolean(
      numPhotos && !galleryListIsLoading && !photographs_are_loading, //&& shouldShowGalleryPhotographs,
    );

  logger.log(
    `conditionsToRenderPhotosAreMet=${conditionsToRenderPhotosAreMet}`,
    {
      isAppendingContent,
      numPhotos,
      galleryListIsLoading,
      photographs_are_loading,
      shouldShowGalleryPhotographs,
    },
  );

  const conditionsToRenderHeroPhotoIsMet =
    isAppendingContent ||
    Boolean(
      !bibNumber &&
        !galleryListIsLoading &&
        galleryPhotographFiltersArePristine,
    );

  useLayoutEffect(() => {
    const scrollContainer = document.querySelector(
      ".gallery-content-container",
    );
    logger.log("pagetopobserver", scrollContainer);
    const pageTopObserver = new IntersectionObserver(
      ([entry]) => {
        logger.log(
          "pagetopobserver entry",
          entry,
          entry.isIntersecting,
          entry.intersectionRatio,
        );
        setIsApproachingTopOfGalleryPage(entry.isIntersecting);
      },
      {
        root: scrollContainer,
        rootMargin: "500px 0px", // adjust this to determine trigger distance for go-to-top
      },
    );
    pageTopObserver?.observe(pageTopSentinel.current);
    return () => pageTopObserver.disconnect();
  }, [pageTopSentinel]);

  const loadMoreObserver = useRef(null);
  useEffect(() => {
    logger.log("loadMoreObserver sentinel load", loadMoreSentinel.current);
    loadMoreObserver.current = new IntersectionObserver(([entry]) => {
      logger.log("loadMoreObserver");
      const { current_page, num_pages } = photographList.getPagination();
      const isLoadingCurrent = galleryList.isLoading();
      logger.log("loadMoreObserver", {
        isLoadingCurrent,
        current_page,
        num_pages,
      });
      if (entry.isIntersecting) {
        if (!isLoadingCurrent && current_page < num_pages) {
          setIsAppendingContent(true);
          const nextPage = current_page + 1;
          logger.log("loadMoreObserver Getting page ...", nextPage, {
            isLoadingCurrent,
            photographsIsEmpty,
          });
          dispatch(
            photographList.updateListPagination({
              ...pagination,
              page: nextPage,
            }),
          );
          if (!photographsIsEmpty) {
            dispatch(
              photographList.fetchListIfNeeded({
                forceUpdate: true,
                callSource: "GalleryPhotographs_LoadMore",
              }),
            );
          }
        }
      }
    });

    return () => {
      loadMoreObserver?.current.disconnect();
    };
  }, [photographsIsEmpty]);

  useLayoutEffect(() => {
    // logger.log("load more sentinel useEffect", {conditionsToRenderPhotosAreMet, loadMoreSentinel, loadMoreObserver});
    if (conditionsToRenderPhotosAreMet && loadMoreSentinel.current) {
      // logger.log("load more sentinel useEffect -> observe", loadMoreSentinel.current);
      loadMoreObserver.current?.observe(loadMoreSentinel.current);
    }
    return () => loadMoreObserver.current?.disconnect();
  }, [conditionsToRenderPhotosAreMet, loadMoreSentinel, galleryPasswordCorrect]);

  useEffect(() => {
    logger.log("gallery id change", galleryId);
    if (galleryId) {
      dispatch(
        galleryList.updateListFilter({
          db_id: galleryId,
          private_event_token: isPrivateEvent
            ? default_private_event_token
            : "",
        }),
      );
      dispatch(
        galleryList.fetchListIfNeeded({ callSource: "GalleryPhotographs_GID" }),
      );
      // shouldn't need both
      // dispatch(galleryList.ensureObjectLoaded(galleryId));
    }
    setGalleryPasswordCorrect(false);
  }, [galleryId]);

  const galleryLoadFetchTriggerKey = `g:${galleryId};f:${customerFaceToken};p:${pageNumber};ts:${timeOfActivity?.start};te:${timeOfActivity?.end};ie:${photographsIsEmpty};f:${galleryPhotographFiltersArePristine}`;

  useEffect(
    function maybeFetchGalleryPhotographs() {
      (async () => {
        logger.log("FX: maybeFetchGalleryPhotographs", {
          galleryListIsLoading,
          galleryId,
          customerFaceToken,
          pageNumber,
          timeOfActivity,
          photographsIsEmpty,
          galleryPhotographFiltersArePristine,
          photographListFilter,
        });

        if (isPrivateEvent && !customerFaceToken) {
          logger.log("FX: maybeFetchGalleryPhotographs NO_FR_TOKEN");
          // skip fetch if no token ready
          return;
        }

        await dispatch(photographList.invalidateList());
        await dispatch(photographList.clearListFilter());
        if (customerFaceToken || bibNumber) {
          let baseFilter = {
            bib_number: bibNumber,
            probable_customer_face_token: customerFaceToken,
            gallery_db_id: get(gallery, "gallery_event")
              ? null
              : get(gallery, "db_id"),
            gallery_event_id: get(gallery, "gallery_event")
              ? get(gallery, "gallery_event")
              : null,
            time_end: get(timeOfActivity, "end", "23:59"),
            time_start: get(timeOfActivity, "start", "0:00"),
            shot_at_before: moment(get(gallery, "shot_at_datetime"))
              .add(2, "days")
              .format(),
            shot_at_after: moment(get(gallery, "shot_at_datetime"))
              .subtract(2, "days")
              .format(),
            private_event_token: isPrivateEvent
              ? default_private_event_token
              : "",
          };
          await dispatch(photographList.updateListFilter(baseFilter));
        } else {
          await dispatch(
            photographList.updateListFilter({
              gallery_db_id: galleryId,
              time_end: get(timeOfActivity, "end", "23:59"),
              time_start: get(timeOfActivity, "start", "0:00"),
            }),
          );
        }

        await dispatch(
          photographList.updatePaginationNumItemsPerPage(
            NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING,
          ),
        );

        logger.log("FX: maybeFetchGalleryPhotographs -> fetchListIfNeeded", {
          galleryLoadFetchTriggerKey,
          galleryId,
          galleryListIsLoading,
          customerFaceToken,
          pageNumber,
          timeOfActivity,
          photographsIsEmpty,
          galleryPhotographFiltersArePristine,
        });

        if (!galleryListIsLoading || photographsIsEmpty) {
          await dispatch(
            photographList.fetchListIfNeeded({
              callSource: "maybeFetchGalleryPhotographs",
            }),
          );
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [galleryLoadFetchTriggerKey],
  );

  useEffect(() => {
    return () => {
      logger.log("FX: Unmount reset");
      dispatch(basePhotographList.invalidateList());
      dispatch(basePhotographList.clearListFilter());
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    logger.log("FX: bypass check", gallery);
    const gallery_event_id = get(gallery, "gallery_event");
    if (gallery_event_id) {
      setHasPasswordBypassprivilege(
        galleryEventDirectAccessUrl.getPasswordBypassStatusForGalleryEventGallery(
          gallery_event_id,
        ),
      );
    }
  }, [gallery]);

  const updateWidth = () => {
    const { innerWidth } = window;
    logger.log("FX: updateWidth", innerWidth);
    setWidth(innerWidth);
  };

  useEffect(() => {
    logger.log("FX: updateZoom", zoom);
    if (zoom < 0 || zoom > 300) {
      dispatch(setZoom(0));
    }
  }, [zoom]);

  useEffect(() => {
    logger.log("FX: photographs -> makePhotoLayoutArr", {
      photographs,
      customerFaceToken,
      timeOfActivity,
    });
    makePhotoLayoutArr();
  }, [
    trolley?.trolley_photographs,
    JSON.stringify(photographs),
    customerFaceToken,
    timeOfActivity,
  ]);

  const handleViewGalleryButtonClicked = () => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const makePhotoLayoutArr = () => {
    if (size(photographs) === 0) {
      setPhotoLayoutList([]);
    } else {
      const photos_for_gallery = [];
      map(photographs, (photograph) => {
        const trolleyPhotograph = head(
          filter(
            get(trolley, "trolley_photographs"),
            (trolley_photograph) =>
              photograph.id === trolley_photograph.photograph.id,
          ),
        );

        let width;
        if (zoom > 0) {
          width =
            Math.round(
              get(photograph, ["gallery_size_file_info", "width_pixels"]),
            ) +
            (Math.round(
              get(photograph, ["gallery_size_file_info", "width_pixels"]),
            ) *
              zoom) /
              100;
        } else {
          width = Math.round(
            get(photograph, ["gallery_size_file_info", "width_pixels"]),
          );
        }

        const data = {
          id: photograph.id,
          price: photograph.price,
          gallery: photograph.gallery,
          photograph_taken_at: photograph.photograph_taken_at,
          photographer: photograph.photographer,
          src: get(
            photograph,
            ["thumbnail_size_file_info", "download_url"],
            get(photograph, ["gallery_size_file_info", "download_url"]),
          ),
          smallSrc: get(photograph, ["avatar_size_file_info", "download_url"]),
          highResSrc: get(photograph, [
            "gallery_size_file_info",
            "download_url",
          ]),
          height: get(photograph, ["gallery_size_file_info", "height_pixels"]),
          width: width,
          filesize: photograph.size,
          sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
          trolleyPhotograph: trolleyPhotograph,
          selected: trolleyPhotograph !== undefined,
        };
        photos_for_gallery.push(data);
      });
      setPhotoLayoutList(photos_for_gallery);
    }
  };

  const onGalleryImageLoaded = (photo) => {};

  const onSelectImage = (pid) => {
    setSelectedImgId(pid);
    setCarouselVisible(true);
  };

  const startGalleryPhotographPageLoadingSequence = () => {
    setShouldShowMapPhotographText(true);
    setShouldShowGalleryPhotographs(true);
  };

  const renderGalleryMetadata = () => {
    return (
      <div>
        <p>Description: {get(gallery, "description")}</p>
      </div>
    );
  };

  const renderMapPhotograph = () => {
    const firstPhotographInGallery = head(photographs);
    const firstPhotographInGallerySrc = get(firstPhotographInGallery, [
      "gallery_size_file_info",
      "download_url",
    ]);
    return (
      <>
        {!photographs_are_loading && (
          <div className={classes.mapPhotographWrapper}>
            {shouldShowMapPhotographText && (
              <>
                <div className={classes.mapPhotographTextWrapper}>
                  <p className={classes.mapPhotographText}>
                    {get(gallery, "gallery_name")}
                  </p>
                </div>
                <div className={classes.mapPhotographViewMoreButtonWrapper}>
                  <p
                    onClick={handleViewGalleryButtonClicked}
                    className={classes.mapPhotographViewMoreButton}
                  >
                    View Gallery
                  </p>
                </div>
              </>
            )}
            <img
              alt=""
              className={classes.mapPhotograph}
              onLoad={startGalleryPhotographPageLoadingSequence}
              src={
                mapPhotographGalleryDownloadUrl || firstPhotographInGallerySrc
              }
            />
          </div>
        )}
      </>
    );
  };

  const renderNoPhotosFound = () => {
    return (
      <div className={classes.noPhotosFoundText}>
        <p>
          No Photos found.
          <br />
          Try clear the filters and search again or contact
          <a href="mailto:info@bossfotos.com">
            &nbsp; info@bossfotos.com &nbsp;
          </a>
          for assistance.
        </p>
      </div>
    );
  };

  const renderGetGalleryPassword = () => {
    return (
      <GalleryPasswordModal
        gallery={gallery}
        onCorrect={() => setGalleryPasswordCorrect(true)}
      />
    );
  };

  const renderNumPhotosFiltered = () => {
    return (
      <p className={classes.numPhotosText + " filter_num_photos_text"}>
        {numPhotos} photo{numPhotos > 1 && "s"} found
      </p>
    );
  };

  const renderPhoto = ({ layout, layoutOptions, imageProps, photo }) => (
    <GalleryImage
      photo={photo}
      gallery={gallery}
      imageProps={imageProps}
      layoutOptions={layoutOptions}
      onImageLoaded={onGalleryImageLoaded}
      onSelectImage={!canSelectForCheckout ? onSelectImage : null}
    />
  );

  const renderCarousel = () => {
    if (isMobile || !carouselVisible) {
      return null;
    }
    return (
      <CarouselPhotos
        gallery={gallery}
        galleryId={galleryId}
        photos={photoLayoutList}
        show={carouselVisible}
        setShowMethod={setCarouselVisible}
        selectedImgId={selectedImgId}
        onImageSelected={setSelectedImgId}
        photographerId={get(gallery, "photographer")}
        photoGrapherName={get(gallery, "photographer_name")}
      />
    );
  };

  const renderPhotos = () => {
    const photosToRender = photoLayoutList;
    const canRenderScrollToTopButton = !isApproachingTopOfGalleryPage;

    const renderMobiFiltersButtonAndNumPhotosFiltered = () => (
      <Stack
        className={classes.numPhotosTextWrapper}
        spacing={5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {Boolean(conditionsToRenderPhotosAreMet) && renderNumPhotosFiltered()}
        {!isPrivateEvent && (
          <Button
            px={5}
            pb={1}
            onClick={onClearFilters}
            startIcon={<CachedIcon />}
            className={classes.mobiClearFiltersButton}
          >
            <p>Clear filters</p>
          </Button>
        )}
      </Stack>
    );

    const renderPhotoAlbum = () => (
      <span ref={photoGalleryRef}>
        <PhotoAlbum
          layout="rows"
          renderPhoto={renderPhoto}
          photos={photosToRender}
        />
        <div ref={loadMoreSentinel}></div>
        {!allPagesLoaded && (
          <TombstoneContainer>
            <Tombstone />
            <Tombstone />
            <Tombstone />
          </TombstoneContainer>
        )}
        {!isMobile && canRenderScrollToTopButton && (
          <ScrollToTopButton
            handleScrollToTop={handleViewGalleryButtonClicked}
          />
        )}
        {isMobile && (
          <ScrollToTopButton
            handleScrollToTop={handleViewGalleryButtonClicked}
          />
        )}
      </span>
    );

    return (
      <>
        <Box
          width={!isMobile ? "96%" : "100%"}
          className="galleryphotographscontainer"
        >
          {!isMobile && (
            <>
              {!galleryPhotographFiltersArePristine &&
                numPhotos > 0 &&
                renderNumPhotosFiltered()}
              {heroPhotoEnabled &&
                conditionsToRenderHeroPhotoIsMet &&
                renderMapPhotograph()}
            </>
          )}
          <div ref={containerRef} className={classes.scrollToPosition} />
          {!galleryPhotographFiltersArePristine &&
            isMobile &&
            renderMobiFiltersButtonAndNumPhotosFiltered()}
          {shouldShowNoPhotosFound && renderNoPhotosFound()}
          <Box className={classes.photoAlbumWrapper}>
            {conditionsToRenderPhotosAreMet && renderPhotoAlbum()}
          </Box>
          {!conditionsToRenderPhotosAreMet && (
            <Box className={classes.loadingTypeWriterWrapper}>
              {!isMobile && (
                <Typography>
                  Hi there - just to let you know your photos are loading
                </Typography>
              )}
              <Typewriter />
            </Box>
          )}
          {get(gallery, "gallery_type") === "photo_art" &&
            renderGalleryMetadata()}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column">
        <div ref={pageTopSentinel}></div>
        {(!get(gallery, "has_gallery_password") ||
          galleryPasswordCorrect ||
          hasPasswordBypassprivilege) &&
          renderPhotos()}
        {get(gallery, "has_gallery_password") &&
          !galleryPasswordCorrect &&
          !hasPasswordBypassprivilege &&
          renderGetGalleryPassword()}
      </Box>
      {renderCarousel()}
    </>
  );
};

export default GalleryPhotographs;
